import React from 'react'
import { Link } from 'react-router-dom'
import MIB from '../../Images/modern-indian-bistro.png'
import Ivory from '../../Images/ivory-dental-clinic.png'
import SeaPride from '../../Images/seap.jpg'
import AgeCalculator from '../../Images/finalAge.jpg'
import TodoApp from '../../Images/finalTODO.jpg'
import QRgenerator from '../../Images/finalQR.jpg'
import Fortune from '../../Images/fortunecafe.jpg'
import TMV from '../../Images/tmvkharghar.jpg'




import './Projects.css'
import Undi from '../../Images/undi.png'



function Projects() {
  return (
    <>
      <div className="projectsContainer">
        {/* <video autoPlay muted loop id="projectVideo">
                    <source src={bgVideo} type="video/mp4"></source>
                    Your browser does not support the video tag.
                </video> */}
        <div className="heading"><h1>My Projects</h1>
          <img src={Undi} alt="Himali" />
        </div>

        {/* <div className="projectWrap">
          <div className="project1">
            <img src={MIB} alt="" />
          </div>
          <div className="projectContent">
            <h2>Modern Indian Bistro</h2>
            <img src={Undi} alt="Himali" />
            <p>Co-developed a responsive React-based website for
              Modern Indian Bistro, a cafe cum restaurant in Kharghar,
              focusing on seamless user experience across all devices.</p>
            <button><Link to="https://modernindianbistro.com/" target='himu'>View Project</Link></button>
          </div>
        </div> */}
        {/* <div className="projectWrap">
          <div className="project1">
            <img src={Ivory} alt="" />
          </div>
          <div className="projectContent">
            <h2>Ivory Dental Clinic</h2>
            <img src={Undi} alt="Himali" />
            <p>Co-developed a responsive React-based website for Ivory
              Dental & Maxillofacial Clinic, utilizing a function-based
              structure and reusing code to ensure a smooth and
              consistent user experience across all devices.</p>
            <button><Link to="https://ivorydentalcare.net.in/" target='himu'>View Project</Link></button>
          </div>
        </div> */}
        <div className="projectWrap">
          <div className="project1">
            <img src={SeaPride} alt="" />
          </div>
          <div className="projectContent">
            <h2>Sea Pride Fine Dine Restaurant</h2>
            <img src={Undi} alt="Himali" />
            <p>Developed a Modern, Responsive React-based website for Sea Pride Fine Dine Restaurant in Belapur. Collaborated closely with the client to understand their requirements and successfully delivered the website.</p>
            <button><Link to="https://seapride.shekharpandge.in/" target='himu'>View Project</Link></button>
          </div>
        </div>
        <div className="projectWrap">
          <div className="project1">
            <img src={TMV} alt="" />
          </div>
          <div className="projectContent">
            <h2>TMV Kharghar</h2>
            <img src={Undi} alt="Himali" />
            <p>Developed a responsive React-based website for
              Tilak Maharashtra Vidyapeeth, Kharghar, one of the best college in Kharghar & Navi Mumbai,
              focusing on seamless user experience across all devices.</p>
            <button><Link to="https://tmvkharghar.shekharpandge.in/" target='himu'>View Project</Link></button>
          </div>
        </div>
        <div className="projectWrap">
          <div className="project1">
            <img src={Fortune} alt="" />
          </div>
          <div className="projectContent">
            <h2>Fortune Bakery Cafe</h2>
            <img src={Undi} alt="Himali" />
            <p>Developed a responsive React-based website for
              Fortune Bakery Cafe, a cafe cum restaurant in Kharghar,
              focusing on seamless user experience across all devices.</p>
            <button><Link to="https://fortunecafe.shekharpandge.in/" target='himu'>View Project</Link></button>
          </div>
        </div>
        <div className="projectWrap">
          <div className="project1">
            <img src={AgeCalculator} alt="" />
          </div>
          <div className="projectContent">
            <h2>Age Calculator</h2>
            <img src={Undi} alt="Himali" />
            <p>Developed a user-friendly age calculator using HTML, CSS, and JavaScript, enabling users to easily calculate their age from birth dates with a clean and intuitive interface.</p>
            <button><Link to="https://age-calculator.shekharpandge.in/" target='himu'>View Project</Link></button>
          </div>
        </div>
        <div className="projectWrap">
          <div className="project1">
            <img src={TodoApp} alt="" />
          </div>
          <div className="projectContent">
            <h2>Todo App</h2>
            <img src={Undi} alt="Himali" />
            <p>CCreated a dynamic todo app using HTML, CSS, and JavaScript, featuring local storage for persistent task management, allowing users to add, edit, and delete tasks while maintaining data even after closing the browser.</p>
            <button><Link to="https://todoapp.shekharpandge.in/" target='himu'>View Project</Link></button>
          </div>
        </div>
        <div className="projectWrap">
          <div className="project1">
            <img src={QRgenerator} alt="" />
          </div>
          <div className="projectContent">
            <h2>QR Code Generator</h2>
            <img src={Undi} alt="Himali" />
            <p>Developed a QR code generator with both backend and frontend, utilizing a backend API to create QR codes with the 'qrcode' package and designing a React frontend that includes a download button for easy saving of generated codes."</p>
            <button><Link to="https://qr-generator.shekharpandge.in/" target='himu'>View Project</Link></button>
          </div>
        </div>

      </div>
    </>
  )
}

export default Projects
